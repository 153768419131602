<template>
  <div class="">
    <div class="box-container q-pa-xl ">
      <q-card class="bg-grey-2 q-pa-md">
        <q-card-section>
          <div class="pic">
            <img :src="'./img/guru/agus.jpeg'" alt="profile-picture" />
          </div>
        </q-card-section>
        <q-card-section class="content">
          <div class="title text-center">
            Agus SP. Amikaton, S.E,M.M
          </div>
          <div class="text-center text-grey">Kepala Sekolah</div>
        </q-card-section>
      </q-card>
    </div>
    <div class="row justify-center q-px-lg ">
      <q-card class="bg-grey-2 q-pa-md q-ma-md" style="width: 210px; height: 240px;" v-for="x in wakasek" :key="x.id">
        <q-card-section style="padding: 0px;">
          <div class="pic" style="width: 110px; height: 110px;">
            <img v-if="x.foto" :src="'./img/guru/' + x.foto" alt="profile-picture" />
            <img v-else-if="x.jenis_kelamin == 'L'" :src="'./img/guru/avatar.png'" alt="profile-picture" />
            <img v-else :src="'./img/guru/avatar2.png'" alt="profile-picture" />
          </div>
        </q-card-section>
        <q-card-section class="content" style="font-size: 12px;">
          <div class="title text-center" style="font-size: 14px;">
            {{ x.nama }}
          </div>
          <div class="text-center text-grey">{{ x.jabatan }}</div>
        </q-card-section>
      </q-card>
    </div>
    <div class=" q-pb-md q-pt-xl text-center text-h5">Guru dan Staff SMP AFBS</div>
    <div :class="maxWidth > 500 ? 'row no-wrap q-pa-sm' : 'wrap'" class="flex justify-between">
      <q-card flat bordered class="q-ma-md" style="width: 100%;">
        <q-card-section class="text-h6 text-center">
          Guru Sekolah
        </q-card-section>
        <q-card-section class="flex row wrap justify-center q-pa-md">
          <div class="q-px-lg justify-center " :class="maxWidth > 500 ? 'row wrap q-pa-sm' : 'wrap'">
            <q-card
              class="bg-grey-2 q-pa-md  q-mx-md q-my-lg"
              style="width: 200px; height: 220px;"
              v-for="x in staff_sekolah"
              :key="x.id"
            >
              <q-card-section style="padding: 0px;">
                <div class="pic" style="width: 100px; height: 100px;">
                  <img v-if="x.foto" :src="'./img/guru/' + x.foto" alt="profile-picture" />
                  <img v-else-if="x.jenis_kelamin == 'L'" :src="'./img/guru/avatar.png'" alt="profile-picture" />
                  <img v-else :src="'./img/guru/avatar2.png'" alt="profile-picture" />
                </div>
              </q-card-section>
              <q-card-section class="content" style="font-size: 12px;">
                <div class="title text-center" style="font-size: 14px;">
                  {{ x.nama }}
                </div>
                <div class="text-center text-grey">{{ x.jabatan }}</div>
              </q-card-section>
            </q-card>
          </div>
        </q-card-section>
      </q-card>
      <q-card flat bordered class="q-ma-md" style="width: 100%;">
        <q-card-section class="text-h6 text-center">
          Staff Boarding
        </q-card-section>
        <q-card-section class="flex row wrap justify-center q-pa-md">
          <div class="q-px-lg justify-center" :class="maxWidth > 500 ? 'row wrap q-pa-sm' : 'wrap'">
            <q-card
              class="bg-grey-2 q-pa-md q-mx-md q-my-lg"
              style="width: 200px; height: 220px;"
              v-for="x in staff_boarding"
              :key="x.id"
            >
              <q-card-section style="padding: 0px;">
                <div class="pic" style="width: 100px; height: 100px;">
                  <img v-if="x.foto" :src="'./img/guru/' + x.foto" alt="profile-picture" />
                  <img v-else-if="x.jenis_kelamin == 'L'" :src="'./img/guru/avatar.png'" alt="profile-picture" />
                  <img v-else :src="'./img/guru/avatar2.png'" alt="profile-picture" />
                </div>
              </q-card-section>
              <q-card-section class="content" style="font-size: 12px;">
                <div class="title text-center" style="font-size: 14px;">
                  {{ x.nama }}
                </div>
                <div class="text-center text-grey">{{ x.jabatan }}</div>
              </q-card-section>
            </q-card>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VueGallery from "vue-gallery";

export default {
  name: "Home",
  components: {
    gallery: VueGallery,
  },
  data() {
    return {
      slide: "style",
      lorem:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.",
      index: null,
      staff_sekolah: [],
      staff_boarding: [],
      wakasek: [],
    };
  },
  mounted() {
    this.$http.get("/daftar_staff_sekolah", {}).then((result) => {
      this.staff_sekolah = result.data;
    });
    this.$http.get("/daftar_staff_boarding", {}).then((result) => {
      this.staff_boarding = result.data;
    });
    this.$http.get("/daftar_wakasek", {}).then((result) => {
      this.wakasek = result.data;
    });
  },
  methods: {},
  computed: {
    maxWidth() {
      return this.$q.screen.width;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-container {
  display: flex;
  min-height: 400px;
  justify-content: center;
}

.box-container2 {
  display: flex;
  height: 250px;
  justify-content: center;
}

.pic {
  position: relative;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  width: 180px;
  height: 180px;
  overflow: hidden;
  transition: all 0.5s ease;
  border-radius: 50%;
  background: #74a3e7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}
.pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;;
}
.content .title {
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #0b8cc4;
}
</style>
